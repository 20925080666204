.card {
  margin: 0;
  position: relative;
  height: 292px;
  display: block;
  cursor: pointer;

  img {
    transition: opacity 500ms ease-out;
  }

  &:hover {
    img {
      opacity: 0.85;
    }
  }

  &:active {
    img {
      opacity: 1;
      transition: unset;
    }
  }

  .section--cards & {
    height: 165px;
    margin-bottom: 13px;
    border-radius: 4px;
    overflow: hidden;

    @include media-breakpoint-up(sm){
      margin-bottom: 1rem;
    }

    @include media-breakpoint-up(md){
      margin-bottom: 18px;
    }

    @include media-breakpoint-up(lg){
      height: 12rem;
    }
  }

  .section--intro & {
    height: 193px;
    margin: 0 -20px;

    @include media-breakpoint-up(sm){
      margin: 0;
      height: 100%;
    }

    @include media-breakpoint-up(lg){
      height: 100%;
    }
  }

  .section--panel & {
    height: 108px;
    margin-bottom: 12px;
    border-radius: 4px;
    overflow: hidden;
    @include media-breakpoint-up(sm){
      height: 185px;
      margin-bottom: 0;
      border-radius: 0;
    }
    @include media-breakpoint-up(lg){
      height: 292px;
    }
  }

  .logo {
    width: 100%;
    padding: 15px 44px;

    @include media-breakpoint-up(lg){
      padding: 15px;
    }
  }

  &__media {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
    overflow: hidden;
  }

  &__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color-green, 0.5);
    color: $color-white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  &__intro {
    display: flex;

    @include media-breakpoint-up(lg){
      align-items: flex-end;
    }

    img {
      object-fit: contain;
      width: 94px;
      
      @include media-breakpoint-up(lg){
        width: auto;
      }

    }
  }

  &__heading {
    text-align: center;

    .title {
      border-bottom: 1px solid rgba($color-white, 1);
      font-size: 20px;
      line-height: 1.1em;
      padding-bottom: 7px;
      margin-bottom: 7px;
      padding-left: 30px;
      padding-right: 30px;

      @include media-breakpoint-up(sm){
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 12px;
        margin-bottom: 12px;
        border-bottom: 1px solid rgba($color-white, 0.45);
      }

      @include media-breakpoint-up(lg){
        font-size: 25px;
        padding-bottom: 24px;
        margin-bottom: 24px;
      }
    }

    .subtitle {
      font-family: $font-family-roboto;
      font-size: 14px;
      line-height: 20px;

      @include media-breakpoint-up(sm){
        font-size: 17px;
      }
    }
  }

  &__arrow {
    width: 40px;
    height: 40px;
    margin: 0 auto;
    position: absolute;
    left: 50%;
    bottom: 25px;
    margin-left: -20px;
    opacity: 0;
    transition: all 0.3s ease;

    @include media-breakpoint-down(sm){
      bottom: 0px;
    }

  }

  

  &:hover {
    .card__arrow {
      opacity: 1;
    }
  }
}


.card--text {

  margin-bottom: 14px;

  @include media-breakpoint-up(sm){
    margin-bottom: $layout-spacing-section-md;
  }
  
  @include media-breakpoint-up(md){
    margin-bottom: $layout-spacing-section;
  }

  .card__heading {
    position: relative;
    width: 100%;
    margin-bottom: 20px;
    padding-bottom: 20px;

  }

  .title {
    border-bottom: 0;
    font-size: 20px;
    line-height: 29px;
    padding-bottom: 0;
    margin-bottom: 0;
    color: $color-green;
    transition: color 0.3s ease;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 55px;
      right: 55px;
      height: 1px;
      background-color: rgba($color-green-25, 0.45);
      transition: background 0.1s ease 0s, left 0.3s ease 0.2s, right 0.3s ease 0.2s;
    }
    

    @include media-breakpoint-up(lg){
      font-size: 24px;
      line-height: 29px;
    }
  }
  
  .card__content {
    padding: 25px 15px 62px;
    background-color: $color-gray-35;
    transition: background 0.3s ease;
    border-radius: 4px;
  }

  .card__abstract {
    color: $color-text;
    text-align: center;
    font-size: 14px;
    line-height: 22px;
    transition: color 0.3s ease;
  }

  .card__cta {
    border-radius: 4px;
    background: $color-green-25;
    color: $color-white;
    text-transform: uppercase;
    width: 163px;
    height: 40px;
    font-family: $font-family-main;
    font-weight: $font-weight-bold;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    line-height: 40px;
    margin-top: 33px;
    border: 1px solid $color-green-25;
    transition: all 0.3s ease;
  }


  &:hover {

    .title {
      color: $color-white;

      &::after {
        left: 20px;
        right: 20px;
        background-color: $color-white;
        opacity: 0.45;
      }
    }

    .card__content {
      background-color: $color-green-25;
    }

    .card__abstract {
      color: $color-white;
    }

    .card__cta {
      background: transparent;
      color: $color-white;
      border: 1px solid $color-white;
    }
  }

  &.card--stroke {
    .card__content {
      border: 2px solid $color-green-25;
      transition: border 0.3s ease;
    }
  }

}

// .card--logo {
//   background-color: $color-gray-35;
//   height: 292px;
//   width: 100%;
//   display: none;
//   flex-direction: column;
//   justify-content: space-between;
//   font-family: $font-family-headings;
//   border-radius: 4px;
//   @include media-breakpoint-up(md){
//     display: flex;
//   }

//   .welcome {
//     padding: 0 16px;

//     &__text {
//       line-height: 20px;
//       font-size: 16px;
//       margin-top: 15px;
//       margin-bottom: 25px;
//       @include media-breakpoint-up(lg){
//         margin-bottom: 15px;
//       }

//       a {
//         text-decoration: none;

//         &:hover {
//           opacity: 0.85;
//         }
        
//         &:active {
//           opacity: 0.75;
//         }
//       }
//     }

//     .button {
//       display: none;
//       @include media-breakpoint-up(lg){
//         display: flex;
//         margin-bottom: 10px;
//         align-items: center;
//       }

//       &:hover {
//         color: $color-green;
//       }

//       &:active {
//         opacity: 0.75;
//       }
//     }
//   }
// }

.card--highlight {
  background-color: $color-green;

  &:hover {
    .card__media {
      opacity: 0.5;
    }
  }

  &:active {
    .card__media {
      transition: unset;
      opacity: 0.35;
    }
  }

  .card__content {
    flex-flow: row wrap;
    background-color: unset;
    background-color: rgba($color-green, 0.4);
    justify-content: space-around;
  }
  .card__title {
    font-size: 22px;
    padding: 0 3%;
    @include media-breakpoint-up(sm){
      font-size: 28px;
    }    
    @include media-breakpoint-up(lg){
      font-size: 42px;
      min-width: 60%;
    }
  }

  .card__media {
    filter: saturate(0.5);
    opacity: 0.6;
    transition: opacity 300ms ease-in-out;
  }

  .card__arrow {
    border-left: 1px solid rgba($color-white, 0.45);
    position: static;
    right: 0;
    opacity: 1;
    width: auto;
    height: auto;
    padding: 10px 3%;
    margin: 0;
    flex-shrink: 0;
    @include media-breakpoint-down(xs) {
      display: none;
    }
    
    .ico-arrow-right {
      display: block;
      height: 48px;
      width: 43px;
    }
  }
}


.card--news {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  
  // height: 100%;
  height: auto;
  text-decoration: none;
  transition: all .4s ease;
  margin: 45px 0;

  .card-header {
    display: flex;
    font-size: 12px;
    margin-bottom: 25px;
  }
  
  .card-date {
    padding-right: 5px;
  }

  .card-tags {
    padding-left: 4px;
    a {
      color: $color-green-25;
    }
  }


  .card-image {
    $width-img: 600px;
    $height-img: 220px;

    margin-bottom: 25px;

    @include aspect-ratio($width-img, $height-img);

    .inner {
       background-position: center;
       background-repeat: no-repeat;
       background-size: cover;
    }
  }

  .card-title {
    margin-bottom: 25px;
    color: $color-green;
     
    span {
      transition: border 0.3s ease;
      border-bottom: 1px solid transparent;
      margin-top: 20px;
    }
  }

  .card-abstract {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 20px;

    p {
      margin-bottom: 20px;
    }
  }

  .button--text {
    text-transform: uppercase;
    display: flex;
    
    .ico-arrow-right {
      transform: translateX(0);
      transition: all 0.3s ease;
    }
  }

  &:hover {
    .card-title span {
      border-bottom: 1px solid #31815F;
    }

    .ico-arrow-right {
      transform: translateX(5px);
    }
  }

}
