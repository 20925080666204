.navigator {
  position: fixed;
  height: 100%;
  width: 100%;
  background: $color-white;
  z-index: 10;
  top: 0;
  padding-top: $layout-header-desktop;
  left: 0;
  overflow: auto;
  opacity: 0;
  pointer-events: none;
  -webkit-clip-path: inset(0 0 100% 0);
  clip-path: inset(0 0 100% 0);
  transition: all 500ms cubic-bezier(0.5, 0.2, 0.5, 0.8);
  @include media-breakpoint-down(sm) {
    padding-top: 100px;
  }

  &.is-open {
    opacity: 1;
    pointer-events: auto;
    -webkit-clip-path: inset(0 0 0 0);
    clip-path: inset(0 0 0 0);
  }

  &__row {
     display: flex;
     flex-wrap: wrap;
     border-bottom: 1px solid #A5B2BA;
     padding-top: 35px;
     padding-bottom: 25px;

     @include media-breakpoint-up(md) {
      flex-wrap: nowrap;
    }

  }

  &__col--list {
    display: flex;
    flex-wrap: wrap;
  }

  &__col {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin: 0;
    @include media-breakpoint-up(md) {
      width: unset;
      flex-flow: row nowrap;
    }

    &:first-child {
      @include media-breakpoint-up(md) {
        width: 300px;
        padding: 0 30px 0 0;
      }
    }

    &:last-child {
      flex-grow: 1;
    }
  }

  &__list {
    width: 50%;
    padding: 0 30px 0 0;
    @include media-breakpoint-up(lg){
      width: 360px;
    }

    a {
      font-size: 13px;
      line-height: 1;
      color: $color-green-25;
      display: inline-flex;
      align-items: center;
      position: relative;

      @include media-breakpoint-up(md){
        font-size: 15px;
        line-height: 28px;
      }

      span:first-child {
        position: relative;
        &::after {
          content: '';
          position: absolute;
          left: 0;
          bottom: 0px;
          width: 100%;
          border-bottom: 1px solid $color-green-25;
          opacity: 0;
          transition: all 0.3s ease;

          @include media-breakpoint-up(md){
            bottom: 6px;
          }
        }
      }

      .ico-arrow-right {
        width: 19px;
        height: 19px;
        transform: translateX(0);
        transition: all 0.3s ease;
        opacity: 0;

        svg path:last-child {
          fill: $color-green-25;
        }
      }

      &:hover {

        span:first-child {
          &::after {
            opacity: 1;
          }
        }

        .ico-arrow-right {
          opacity: 1;
          transform: translateX(5px);
        }
      }
    }
  }

  &__heading {
    width: 300px;
    color: $color-green;

    .title {
      font-family: $font-family-headings;
      font-size: 24px;
      line-height: 26px;
      margin-bottom: 0.5rem;
      @include media-breakpoint-up(md){
        font-size: 26px;
        line-height: 29px;
      }
    }
  
    .sub {
      font-family: $font-family-roboto;
      font-size: 15px;
      line-height: 1;
      color: $color-green;
      opacity: 0.575;
      @include media-breakpoint-up(md){
        font-size: 17px;
        line-height: 20px;
      }
    }
  }

  &__close {
    position: relative;
    width: 40px;
    margin: 60px auto 100px;
    padding-top: 40px;
    font-weight: $font-weight-bold;
    text-align: center;
    cursor: pointer;

    span {
      color: $color-green-25;
      font-family: $font-family-roboto;
      font-size: 12px;
      text-transform: uppercase;
      transition: color 0.3s ease;
    }
    
    .icon-close {
      width: 34px;
      height: 30px;
      margin: 0 auto;
      position: relative;
      transition: transform 0.2s ease;

      &:before, 
      &:after {
        position: absolute;
        left: 15px;
        content: ' ';
        height: 25px;
        width: 3px;
        background-color: $color-green-25;
        transition: background 0.3s ease;
        }
        &:before {
        transform: rotate(45deg);
        }
        &:after {
        transform: rotate(-45deg);
        }
        
    }

    &:hover {
      span {
        color: $color-green;
      }
      .icon-close {
        transform: scale(0.9);
        &:before, 
        &:after {
          background-color: $color-green;
        }
      }
      
    }
  }

  
}
