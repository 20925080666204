.d-persons {
  margin-top: $layout-spacing-section;
  @include media-breakpoint-down(md) {
    margin-top: $layout-spacing-section-md;
  }

  .d-persons-letters {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    font-family: $font-family-headings;
    font-weight: 700;
    @include media-breakpoint-down(md) {
      flex-flow: column nowrap;
      justify-content: center;
    }

    span {
      display: block;
      margin-right: 1rem;
      flex-shrink: 0;
      color: $color-gray-700;
      @include media-breakpoint-down(md) {
        margin: 0 0 0.5rem;
      }
    }

    ul {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
      flex-grow: 1;
      user-select: none;
      @include media-breakpoint-down(md) {
        flex-flow: row wrap;
      }

      li {
        position: relative;
        width: 3rem;
        text-align: center;
        cursor: pointer;

        &.d-persons-letter--active {
          color: $color-primary;
        }

        &:last-child::after {
          display: none;
        }

        &::after {
          content: "";
          position: absolute;
          top: 0.25rem;
          right: 0;
          height: calc(100% - 0.5rem);
          width: 1px;
          background-color: $color-gray-500;
        }

        &:hover {
          color: $color-primary;

          &::after {
            color: $color-gray-700;
          }
        }

        &:active {
          color: $color-primary-alt;

          &::after {
            color: $color-gray-700;
          }
        }
      }
    }
  }

  .d-persons-list {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: stretch;
    margin-top: 3rem;

    .d-persons-person {
      width: calc(33.333333% - 12px);
      margin: 0 18px 18px 0;
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
      @include media-breakpoint-down(md) {
        width: 100%;
        margin: 0 0 18px 0;
      }

      &:nth-child(3n) {
        margin-right: 0;
      }

      img {
        display: block;
        width: 6rem;
        height: 6rem;
        object-fit: cover;
        object-position: center;
        border-radius: 3px;
        margin-right: 18px;
      }

      strong {
        display: block;
        color: $color-primary;
      }
      
      span {
        display: block;
        color: $color-primary-alt;
      }
    }
  }
}
