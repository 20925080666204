$layout-container-maxwidth: 1280px;
$layout-container-sketch: 1920px;
$layout-page-padding: 20px;

$layout-spacing-section: 25px;
$layout-spacing-section-md: 25px;

$layout-nav-mobile: 71px;
$layout-header-desktop: 50px;

$layout-grid-gutter: 18px;
