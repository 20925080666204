

/* ==========================================================================

   ### Visibility Helpers

   ``````
   ========================================================================== */

.u-hidden,
//also as global state
.is-hidden,
.js .is-jshidden {
	display: none !important;
	visibility: hidden;
}

//also as global state
.u-invisible,
.is-invisible {
	visibility: hidden;
}