*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}


body,
html {
  margin: 0;
  padding: 0;
  font-size: 17px;
}

html {
  font-size: $font-size-base;
}

body {
  font-size: 1rem;
  font-family: $font-family-main;
  line-height: $font-lineheight-base;
  color: $color-text;
  background-image: url("../images/background.jpg");
  background-position: 50% 0;
  background-size: 100% auto;
  background-repeat: no-repeat;
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.unscrollable {
	  overflow: hidden;
	  height: 100vh;
  }
}

::selection {  background-color: $color-gray-50; color: $color-black; -webkit-text-fill-color: $color-black;}
::-moz-selection { background-color: $color-gray-50; color: $color-black; -webkit-text-fill-color: $color-black; }

*:focus {
  outline: none;
}

a,
img {
  border: none;
}

figure {
  padding: 0;
  margin: 0;
}

input,
select,
select:focus,
textarea,
button {
  border-radius: 0;
  appearance: none;
  border: 0;
  outline: 0;
  -webkit-appearance: none;
  background-color: transparent;
}

input::-ms-clear {
  display: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-text-fill-color: inherit !important;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}


button {
  overflow: visible;
  box-shadow: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

[type=reset],
[type=submit],
button,
html [type=button] {
  -webkit-appearance: button;
}

[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner,
button::-moz-focus-inner {
  border-style: none;
  padding: 0
}

ul, li {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

.ico-arrow-right {
  display: inline-block;
  width: 100%;
  height: 100%;

  svg {
    height: 100%;
    width: 100%;
  }

  svg path:last-child {
    fill: $color-white;
  }

  &.ico--green {
    svg path:last-child {
      fill: $color-green;
    }
  }
  &.ico--green-25 {
    svg path:last-child {
      fill: $color-green-25;
    }
  }
}

.separator { 
  width: 100%;
  height: 1px;
  color: $color-text;
  background-color: $color-text;
  border: none;
  opacity: 0.45;
  margin-top: $layout-spacing-section;
  margin-bottom: $layout-spacing-section;
  
  &--nomargin {
    margin: 0;
  }
}

.page {
  min-height: 70vh;
  @include media-breakpoint-down(sm) {
    margin-top: 130px;
    min-height: auto;
  }
}

[data-component] {
  visibility: hidden;
}

.video-wrapper{
  position:relative;

  &::before{
    content: "";
    display: block;
    padding-bottom: 56.25%;
  }

  iframe{
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
