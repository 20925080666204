.d-ui-button {
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  background-color: $color-green-25;
  color: $color-text-alt;
  white-space: nowrap;
  line-height: 3em;
  padding: 0 1.5em;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  &:hover {
    background-color: $color-green;
  }

  &:active {
    color: rgba($color-text-alt, 0.5);
  }

  svg {
    margin-left: 1em;
    width: 18px;
    height: 18px;

    path {
      fill: $color-text-alt;
    }
  }
}
