@import "vendor/normalize";
@import "vendor/bootstrap/bootstrap-grid";


// Settings
@import "settings/layout"; 
@import "settings/colors";
@import "settings/fonts";
@import "settings/levels";
// @import "settings/breakpoints";
@import "settings/easings";

// Tools
@import "tools/functions";
@import "tools/mixins";

// Base
@import "base/grid";
@import "base/global";
@import "base/typography";
@import "base/buttons";
@import "base/rte";
@import "base/ui";

// Utils
@import "utils/flexbox";
@import "utils/visibility";

// Components
@import "components/navigator";
@import "components/header";
@import "components/footer";
@import "components/card";
@import "components/home-news";
@import "components/news-panel";
@import "components/section";
@import "components/banner";
@import "components/galleries";
@import "components/gallery";
@import "components/hero";
@import "components/hamburger";
// @import "components/search";
@import "components/events";
@import "components/media";
@import "components/meetings-list";
@import "components/contact-detail";
@import "components/logo-box";
@import "components/persons";
@import "components/podcasts";
@import "components/breadcrumbs";

// New components 08-2020
@import "components/logo";
@import "components/map";
@import "components/top-slider";

@import "../scss-alt/main.scss";
