.card {
  &:hover {
    .card__arrow {
      opacity: 1;
      transform: translateX(0);
    }
  }

  &:active {
    .card__arrow {
      transition: unset;
      opacity: 0.5;
    }
  }

  &.card--text .card__content {
    padding: unset;
  }

  &__content {
    background-color: unset;
    flex-flow: column;
  }

  &__heading {
    padding: 0 15px;
  }

  &__abstract {
    padding: 0 15px;
  }

  &__media {
    transition: background-color 300ms ease-out;
    background-color: $color-gray-800;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      opacity: 0.7;
      // mix-blend-mode: multiply;
    }
  }

  &__arrow {
    transform: translateX(-10px);
    transition: all 300ms ease-in-out;
  }

  &.card--news {
    .card-image {
      &::before {
        display: none;
      }

      img {
        display: block;
        width: 100%;
        height: 30vw;
        object-fit: cover;
        object-position: center;
        border-radius: 4px;
        @include media-breakpoint-up(sm) {
          height: 20vw;
        }
        @include media-breakpoint-up(md) {
          height: 220px;
        }
      }
    }
  }
}