.d-galleries {
  .d-galleries-wrapper {
    margin: ($layout-grid-gutter * 2) 0;
    padding-top: $layout-grid-gutter * 2;
    border-top: 1px solid $color-border;
    border-bottom: 1px solid $color-border;
    @include media-breakpoint-down(md) {
      margin-top: $layout-grid-gutter;
      padding-top: $layout-grid-gutter;
    }
    
    > h2 {
      color: $color-green;
      margin-bottom: $layout-grid-gutter;
    }
  }


  ul {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: stretch;

    li {
      width: calc(25% - 13.5px);
      margin: 0 $layout-grid-gutter ($layout-grid-gutter * 2) 0;
      @include media-breakpoint-down(md) {
        width: calc(50% - 9px);
        margin: 0 $layout-grid-gutter ($layout-grid-gutter * 2) 0;
      }
      @include media-breakpoint-down(sm) {
        width: 100%;
        margin: 0 0 36px;
      }

      &:nth-child(2n) {
        @include media-breakpoint-down(md) {
          margin-right: 0;
        }
      }

      &:nth-child(4n) {
        margin-right: 0;
      }
    }
  }

  .d-galleries-item {
    p {
      margin-top: 1rem;
      margin-bottom: 0;
    }

    a.button.button--text {
      color: $color-green;
      text-transform: uppercase;

      &:hover {
        color: $color-green-25;
      }
      
      &:active {
        color: rgba($color-green-25, 0.75);
      }
    }
  }

  .d-galleries-thumb {
    display: block;
    position: relative;
    border-radius: 3px;
    overflow: hidden;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    &:hover {
      &::after {
        background-color: rgba($color-black, 0.5);
      }

      h2 {
        color: $color-green-25;
      }
    }
    
    &:active {
      h2 {
        color: rgba($color-green-25, 0.7);
      }
    }

    &::before {
      content: "";
      display: block;
      padding-bottom: 70%;
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($color-black, 0.2);
      transition: background-color 300ms ease-in-out;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      position: absolute;
      top: 0;
      left: 0;
    }

    h2 {
      padding: 1rem;
      text-align: center;
      position: relative;
      z-index: 2;
      color: $color-text-alt;
      text-shadow: 0 1px 1px rgba($color-black, 0.2);
      transition: color 300ms ease-in-out;
    }
  }
}
