.news-panel {
  display: block;
  transition: background-color 0.3s ease;
  border-radius: 4px;

  @include media-breakpoint-up(sm){
    display: flex;
    background-color: rgba($color-green, 0.14);
    height: 172px;
  }

  &__col {
    display: block;
    
    @include media-breakpoint-up(sm){
      display: flex;
      max-width: 790px;
      overflow: hidden;
    }

    @include media-breakpoint-up(md){
      max-width: 1050px;
    }
  }

  &__controls {
    
    cursor: pointer;

    @include media-breakpoint-up(sm){
      width: 43px;
      border-left: 1px solid $color-white;
    }

    .button--arrow {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      transform: rotate(90deg);

      @include media-breakpoint-up(sm){
        transform: none;
        height: 100%;
      }

    }

    &:hover {
      background-color: rgba($color-green, 0.10);
    }
  }

  &__heading {
    display: block;
    align-items: center;
    height: 100%;
    padding-right: 20px;
    border-right: 1px solid $color-white;

    @include media-breakpoint-up(sm){
      display: flex;
    }
  }

  &__title {
    font-size: 20px;
    line-height: 18px;
    color: $color-green;
    margin-left: 35px;
    margin-right: 5px;
    text-align: center;

    @include media-breakpoint-up(sm){
      font-size: 15px;
      line-height: 18px;
      text-align: left;
    }

    @include media-breakpoint-up(lg){
      font-size: 24px;
      line-height: 29px;
    }

    br {
      display: none;
      @include media-breakpoint-up(sm){
        display: block;
      }
    }
  }

  &__items {
    @include media-breakpoint-up(sm){
      display: flex;
    }
  }

  &__item {
    display: block;
    padding: 25px 25px 25px 25px;
    height: 100%;
    border-bottom: 1px solid $color-green;

    @include media-breakpoint-up(sm){
      border-bottom: none;
      width: 400px;
    }

    @include media-breakpoint-up(lg){
      width: auto;
      padding: 25px 0px 25px 25px;
    }

    &:first-child {
      border-top: 1px solid $color-green;
      margin-top: 30px;

      @include media-breakpoint-up(sm){
        border-top: none;
        margin-top: 0;
      }
    }

    h3 {
      font-family: $font-family-roboto;
      font-weight: $font-weight-medium;
      font-size: 17px;
      line-height: 20px;
      color: $color-green;
      margin-bottom: 10px;
      
      @include media-breakpoint-up(sm){
        height: $layout-spacing-section;
        margin-bottom: 3px;
      }

      span {
        transition: border 0.3s ease;
        border-bottom: 1px solid transparent;
        margin-top: 20px;
      }
    }

    p {
      font-size: 12px;
      line-height: 16px;
      font-family: $font-family-roboto;
      margin-bottom: 13px;
    }

    .button--text {
      color: $color-green-25;
      
    }

    &:hover {
      background-color: rgba($color-green, 0.10);

      h3 {
        span {
          border-bottom: 1px solid $color-green;
        }
      }
    }
  }
}