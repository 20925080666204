.d-topslider {
  position: relative;
  margin-top: $layout-spacing-section;
  height: 25rem;
  overflow: hidden;
  @include media-breakpoint-down(md) {
    height: 20rem;
  }
  @include media-breakpoint-down(sm) {
    height: 50vw;
  }

  .swiper-container {
    height: 100%;

    .swiper-slide {
      border-radius: 3px;
      overflow: hidden;
      background-color: $color-primary-alt;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($color-dark, 0.5);
      }

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-position: center;
        object-fit: cover;
      }

      .d-topslider-text {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        text-align: center;
        left: calc(5rem + 75px);
        width: calc(100% - 150px - 10rem);
        z-index: 2;
        color: $color-text-alt;
        @include media-breakpoint-down(sm) {
          width: calc(100% - 100px - 2rem);
          left: calc(50px + 1rem);
        }
      }

      h3 {
        font-size: 42px;
        line-height: 1.2em;
        margin-bottom: 0.5rem;
        @include media-breakpoint-down(md) {
          font-size: 5vw;
          margin-bottom: 1vw;
        }
        
      }

      p {
        font-size: 18px;
        margin-bottom: 0;
        @include media-breakpoint-down(md) {
          font-size: 2.5vw;
        }
      }
    }
  }

  &-prev,
  &-next {
    position: absolute;
    top: calc(50% - 24px);
    cursor: pointer;
    z-index: 2;
    @include media-breakpoint-down(sm) {
      display: none;
      top: calc(50% - 4vw);
    }

    &:hover {
      svg path {
        fill: $color-primary;
      }

      span {
        color: $color-primary;
      }
    }

    &:active {
      svg path {
        fill: $color-primary-alt;
      }

      span {
        color: $color-primary-alt;
      }
    }

    &::after {
      content: "";
      position: absolute;
      width: 1px;
      height: 150%;
      top: -25%;
      background-color: rgba($color-text-alt, 0.5);
    }

    svg {
      pointer-events: none;
      width: 48px;
      height: 48px;
      @include media-breakpoint-down(sm) {
        width: 8vw;
        height: 8vw;
      }

      path {
        fill: $color-text-alt;
      }
    }
  }

  &-prev {
    left: 1rem;
    @include media-breakpoint-down(sm) {
      left: 0;
    }

    &::after {
      right: 0;
    }

    &--back {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
      width: auto;
      @include media-breakpoint-down(sm) {
        display: block;
      }

      span {
        color: $color-text-alt;
        font-size: 11px;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 1px;
        @include media-breakpoint-down(md) {
          display: none;
        }
      }
    }
  }
  
  &-next {
    right: 1rem;
    @include media-breakpoint-down(sm) {
      right: 0;
    }
    
    &::after {
      left: 0;
    }

    svg {
      transform: rotate(180deg);
    }
  }

  &-indexes {
    position: absolute;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    z-index: 2;

    li {
      width: 0.75rem;
      height: 0.75rem;
      border-radius: 50%;
      background-color: $color-text-alt;
      margin: 0 0.2rem;
      opacity: 0.5;
      cursor: pointer;
      transition: opacity 300ms ease-in-out;

      &:hover,
      &.d-topslider-index--active {
        opacity: 1;
      }
    }
  }
}
