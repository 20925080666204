.d-podcasts {
  .d-podcasts-wrapper {
    margin-top: $layout-spacing-section;
    padding-top: $layout-spacing-section;
    padding-bottom: $layout-spacing-section;
    border-top: 1px solid $color-border;
    border-bottom: 1px solid $color-border;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: stretch;
    @include media-breakpoint-down(md) {
      margin-top: $layout-spacing-section-md;
      padding-top: $layout-spacing-section-md;
      padding-bottom: $layout-spacing-section-md;
      flex-flow: column nowrap;
      align-items: flex-start;
    }
  }

  .d-podcasts-sidebar {
    width: 33.3333%;
    flex-shrink: 0;
    margin-right: $layout-grid-gutter * 2;
    padding-right: $layout-grid-gutter * 2;
    border-right: 1px solid $color-border;
    @include media-breakpoint-down(md) {
      width: 100%;
      padding-right: 0;
      border-right-width: 0;
      margin: 0 0 $layout-grid-gutter * 2;
    }
  }
  
  .d-podcasts-main {
    flex-grow: 1;

    h1 {
      color: $color-green;
      margin-bottom: $layout-grid-gutter;
    }

    ul li:last-child .d-podcasts-item {
      margin-bottom: 0;
    }
  }

  .d-podcasts-filters {
    border: 1px solid $color-border;
    border-radius: 6px;

    ul {
      padding: $layout-grid-gutter;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 500;

      .d-podcasts-filter {
        cursor: pointer;
        user-select: none;

        &.d-podcasts-filter--active,
        &:hover {
          color: $color-green;
        }

        &:active {
          color: $color-green-25;
        }
      }
    }
  }

  .d-podcasts-filterstop {
    padding: $layout-grid-gutter;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.05em;
    border-bottom: 1px solid $color-border;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    svg {
      display: block;
      transform: rotate(-90deg);

      path {
        fill: $color-text;
      }
    }
  }

  .d-podcasts-item {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: $layout-grid-gutter * 2;
    @include media-breakpoint-down(md) {
      flex-flow: column nowrap;
      justify-content: flex-start;
      align-items: flex-start;
    }

    > div {
      flex-grow: 1;
    }

    h2 {
      color: $color-green;
      margin-bottom: $layout-grid-gutter / 3;
    }

    p {
      font-size: 14px;
      line-height: 1.25em;
      @include media-breakpoint-down(md) {
        margin-bottom: 1rem;
      }
    }

    a {
      flex-shrink: 0;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      border: 1px solid $color-green;
      width: 6rem;
      height: 6rem;
      border-radius: 6px;
      text-align: center;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 500;
      color: $color-green;
      margin-left: $layout-grid-gutter * 2;
      @include media-breakpoint-down(md) {
        margin-left: 0;
      }

      &:hover {
        background-color: $color-green;
        color: $color-white;

        svg path {
          fill: $color-white;
        }
      }

      &:active {
        svg {
          opacity: 0.5;
        }
      }

      svg {
        display: block;
        width: 24px;
        height: 24px;

        path {
          fill: $color-green;
        }
      }
    }
  }

  .d-podcats-meta {
    font-size: 12px;
    letter-spacing: 0.05em;
    
    span {
      display: inline-block;
      // margin-right: 0.5rem;
      
      &:first-child {
        font-weight: 500;
        text-transform: uppercase;
        color: $color-green;
      }

      &:nth-child(2) {
        &::before {
          content: " - "
        }
      }

      &:nth-child(3) {
        font-weight: 700;

        &::before {
          content: " - ";
          font-weight: 400;
        }
      }
    }
  }

  .d-podcasts-pagination {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    border-top: 1px solid $color-border;
    padding: 1rem 0 0;
    user-select: none;

    span {
      display: block;
      text-align: center;
      cursor: pointer;
      margin: 0 0.75rem 0.75rem 0;
      font-weight: 500;

      &.active,
      &:hover {
        color: $color-green;
      }
      
      &:active {
        color: rgba($color-green, 0.5);
      }
    }
  }
}
