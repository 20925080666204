.rte {

  h1, h2, h3, h4 {
    color: $color-green;
  }

  i {
    text-decoration: underline;
  }

  a {
    color: $color-green-25;
    text-decoration: underline;
  }

  .section-rte-page & {
    p {
      font-size: 16px;
      letter-spacing: normal;
      line-height: 24px;
    }

    h2 {
      font-size: 22px;
      line-height: 29px;
      margin-bottom: 30px;

      @include media-breakpoint-up(md){
        margin-bottom: 40px;
      }
    }

    h1 {
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 30px;
    }
  }

  img {
    width: 100%;
    height: auto;
    margin-bottom: 30px;
  }

  > * {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ul li {
    list-style-type: square;
  }

  ol {
    margin-top: 0;
    padding-left: 0;

    li {
      list-style-type: decimal;
    }
  }

  iframe {
    border-width: 0;
  }

  figure {
    img {
      display: block;
      width: 100%;
      height: auto;
      margin-bottom: 0;
    }

    figcaption {
      opacity: 0.5;
      font-style: italic;
      text-align: center;
      margin-top: 12px;
    }

    &.video {
      position: relative;

      &::before {
        content: "";
        display: block;
        width: 100%;
        padding-bottom: 56.25%;
      }

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  blockquote {
    border-left: 3px solid $color-primary;
    padding-left: 1rem;
    font-size: 1.5rem;
    line-height: 1.5em;
    color: $color-primary-alt;
  }

  a.button--rte {
    color: $color-white;
    text-decoration: none;

    &:active {
      color: rgba($color-white, 0.5);
    }
  }

  .image-center {
    text-align: center;

    img {
      display: inline-block;
      width: 33.3333333%;
      height: auto;
      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }
  }

  .image-left {
    float: left;
    width: 33.3333333%;
    margin-right: 24px;
    @include media-breakpoint-down(md) {
      float: none;
      width: 100%;
      margin-right: 0;
    }

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
  
  .image-right {
    float: right;
    width: 33.3333333%;
    margin-left: 24px;
    @include media-breakpoint-down(md) {
      float: none;
      width: 100%;
      margin-left: 0;
    }

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
}
