.d-breadcrumbs {
  margin-top: 25px;
  text-align: center;
  color: $color-text;
  @include media-breakpoint-down(sm) {
    display: none;
  }
  
  a {
    color: $color-green;

    &:hover {
      text-decoration: underline;
    }
  }

  span {
    color: $color-text;
  }
}
