.contact-detail {
  a {
    &:hover {
      color: $color-green; 
    }

    &:active {
      color: $color-green-25;
    }
  }

  &__name {
    &::after {
      display: none;
    }
  }
}