.d-map {
  margin-top: $layout-spacing-section;
  @include media-breakpoint-down(md) {
    margin-top: $layout-spacing-section-md;
  }

  @for $i from 1 to 15 {
    &.d-map--#{$i} {
      .d-map-areas > g {
        opacity: 1;
        
        &:not(.d-map-area--#{$i}) path {
          fill: $color-border !important;
        }
      }
    }
  }

  &.d-map--map {
    .d-map-area:not(:hover) {
      path {
        fill: #CBBBA0 !important;
      }
    }

    // zoom mappa

    .d-map-mapwrapper {
      transform: scale(1.3);
      @include media-breakpoint-down(md) {
        transform: unset;
      }
    }
  }
  
  &-inner {
    border-top: 1px solid $color-border;
    padding-top: $layout-spacing-section;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: stretch;
    @include media-breakpoint-down(md){
      padding-top: $layout-spacing-section-md;
      flex-flow: column nowrap;
      align-items: flex-start;
    }

    > *:first-child {
      border-right: 1px solid $color-border;
      @include media-breakpoint-down(md) {
        border-right-width: 0;
      }
    }
  }

  &-list,
  &-map,
  &-gallery {
    width: 50%;
    flex-shrink: 0;
    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }

  &-list {
    padding-right: 2rem;
    @include media-breakpoint-down(md) {
      padding-right: 0;
    }

    h1 {
      color: $color-primary;
      margin-bottom: 2rem;
    }

    ul {
      list-style-type: none;
      font-family: $font-family-headings;
      font-weight: 700;
      text-transform: uppercase;

      li {        
        &:last-child {
          margin-bottom: 0;
        }

        a {
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-between;
          align-items: center;
          border-radius: 3px;
          padding: 0.25rem 0.5rem;
          
          &:hover {
            background-color: $color-primary-alt;
            color: $color-text-alt;

            svg {
              opacity: 1;
            }
          }

          svg {
            opacity: 0;

            path {
              fill: $color-text-alt;
            }
          }
        }
      }
    }
  }

  &-tooltip {
    position: absolute;
    background-color: rgba($color-primary-alt, 0.9);
    color: $color-text-alt;
    padding: 0.25rem 0.5rem;
    border-radius: 3px;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 0.5px;
    opacity: 0;
    pointer-events: none;
    transform-origin: center;
    transform: translate(-50%, -120%);
    transform-origin: 50% calc(100% + 5px);
    z-index: 2;
    
    &--active {
      opacity: 1;
      transition: all 500ms ease-in-out;
      transform: translate(-50%, -100%);
    }

    &::after {
      content: "";
      display: block;
      width: 0; 
      height: 0; 
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;      
      border-top: 5px solid rgba($color-primary-alt, 0.9);
      position: absolute;
      bottom: -5px;
      left: calc(50% - 5px);
    }
  }

  &-mapwrapper {
    position: relative;
    padding-bottom: 100%;
    overflow: hidden;

    svg {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &-map {
    position: relative;
    padding-left: 2rem;
    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  &-gallery {
    position: relative;
    padding-left: 2rem;
    @include media-breakpoint-down(md) {
      padding-left: 0;
    }

    &wrapper {
      position: relative;
      margin-bottom: 1rem;

      &::before {
        content: "";
        display: block;
        padding-bottom: 100%;
      }
    }

    .swiper-container {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    .swiper-button-prev,
    .swiper-button-next {
      width: 48px;
      height: 48px;

      &::after {
        display: none;
      }

      &:hover {
        svg path {
          fill: $color-primary;
        }
      }
      
      &:active {
        svg path {
          fill: $color-primary-alt;
        }
      }

      svg {
        display: block;
        width: 100%;
        height: 100%;

        path {
          fill: $color-text-alt;
        }
      }
    }

    .swiper-button-next {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  &-gallerycaption {
    display: none;
    text-align: center;

    &--active {
      display: block;
    }
  }

  &-areas {
    > g {
      opacity: 0.58;
      cursor: pointer;

      &:hover {
        opacity: 0.85;
      }
    }
  }

  &-lake {
    pointer-events: none;
  }

  &-back {
    color: $color-primary;
    display: inline-flex;
    margin-bottom: 1rem;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;

    &:hover {
      color: $color-primary-alt;
    }

    svg {
      display: block;
      width: 12px;
      height: 12px;

      path {
        fill: $color-primary;
      }
    }
  }
}
