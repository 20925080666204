
.footer {
  margin-top: $layout-spacing-section-md;
  padding: $layout-spacing-section-md 0;
  background-color: $color-green;
  color: $color-white;

  @include media-breakpoint-up(lg){
    margin-top: $layout-spacing-section;
    padding: $layout-spacing-section 0;
  }

  &__title {
    font-size: 24px;
    line-height: 29px;
    margin-bottom: $layout-spacing-section-md;
    text-align: center;
    font-family: $font-family-headings;

    @include media-breakpoint-up(sm){
      margin-bottom: $layout-spacing-section;
    }
  }

  &__logo {
    margin-bottom: 20px;

    @include media-breakpoint-up(sm){
      margin-right: 25px;
      margin-bottom: 0;
    }
  }

  &__bottom {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    // padding-top: $layout-spacing-section-md;

    @include media-breakpoint-up(sm){
      flex-wrap: nowrap;
    }

    // @include media-breakpoint-up(lg){
    //   padding-top: $layout-spacing-section;
    // }

    a {
      cursor: pointer;
      position: relative;
  
      &::after {
        content: '';
        border-bottom: 1px solid $color-green-50;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        opacity: 0;
        transition: opacity 0.3s ease;
      }
  
      &:hover {
        color: $color-green-50;
  
        &::after {
          opacity: 1;
        };

      }
    }

  }


  &__copy {    
    p {
      margin-bottom: 0;
      font-family: $font-family-roboto;
      font-size: 11px;
      line-height: 15px;

      @include media-breakpoint-up(sm){
        font-size: 12px;
        line-height: 20px;
        text-align: left;
      }
    }
  }

  .button {
    margin-bottom: 20px;

    @include media-breakpoint-up(sm){
      margin-bottom: 0;
    }
  }

  
}
