.d-logo {
  margin-top: $layout-header-desktop + $layout-spacing-section;
  text-align: center;
  @include media-breakpoint-down(sm) {
    display: none;
  }

  a {
    display: inline-block;

    &:active {
      opacity: 0.8;
    }

    img {
      display: block;
      width: auto;
      height: 4rem;
    }
  }
}
