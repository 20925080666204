$font-family-headings: 'PT Serif', serif;
$font-family-roboto: 'Roboto', sans-serif;
$font-family-lato: 'Lato', sans-serif;
$font-family-main: $font-family-roboto;

$font-size-base: 16px !default;
$font-lineheight-base: 1.5em;

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;
