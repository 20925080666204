.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $level-index-nav;
  width: 100%;
  user-select: none;

  &__desktop {
    display: none;
    background: $color-green;
    color: $color-white;
    height: $layout-header-desktop;

    @include media-breakpoint-up(md){
      display: block;
    }
  }

  &__topbar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 50px;

    a {
      font-family: $font-family-roboto;
      font-size: 12px;
      font-weight: $font-weight-bold;
      border-right: 1px solid rgba($color-white, 0.6);
      color: rgba($color-white, 0.6);
      display: block;
      padding: 0 19px;
      transition: color 0.3s ease;
      white-space: nowrap;

      &:hover {
        color: rgba($color-white, 1);
      }

      &:last-of-type {
        border-right: none;
      }
    }
  }

  &__search {
    text-transform: uppercase;
    font-family: $font-family-roboto;
    font-weight: $font-weight-bold;
    font-size: 12px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    padding: 0 20px;
    background: $color-green-25;
    height: 100%;
    cursor: pointer;
    transition: all 300ms ease-out;

    svg {
      margin-right: 5px;
      pointer-events: none;
      display: block;

      path {
        fill: $color-white;
        transition: fill 300ms ease-out;
      }
    }

    &--active,
    &:hover {
      background-color: white;
      color: $color-green;

      svg path {
        fill: $color-green;
      }
    }
  }


  &__news {
    display: none;
    font-size: 12px;
    color: $color-white;
    align-items: center;
    @include media-breakpoint-up(sm){
      display: none;
    }
    @include media-breakpoint-up(lg){
      display: flex;
    }
    
    .label {    
      text-decoration: none; 
      padding: 13px 0 12px 10px;
      background: $color-green-25; 
      position: relative; 
      display: block;
      float: left;
      font-family: $font-family-roboto;
      font-weight: $font-weight-bold;
      text-transform: uppercase;

      &::after { 
        content: " "; 
        display: block; 
        width: 0; 
        height: 0;
        border-top: 25px solid transparent; /* Go big on the size, and let overflow hide */
        border-bottom: 25px solid transparent;
        border-left: 25px solid $color-green-25;
        position: absolute;
        top: 50%;
        margin-top: -25px; 
        left: 100%;
        z-index: 2; 
      }

      &::before {
        content: " "; 
        display: block; 
        background: $color-green-25; 
        position: absolute;
        height: 100%;
        top: 0;
        left: -50vw;
        width: 50vw;
      }
    }

    .title {
      font-family: $font-family-roboto;
      font-weight: $font-weight-medium;
      padding-left: $layout-spacing-section * 1.5;
      margin-right: 17px;
      max-width: 50%;
    } 
  }

  &__mobiletop {
    display: none;
    background-color: $color-primary;
    color: rgba($color-text-alt, 0.75);
    text-align: center;
    font-size: 12px;
    padding: 0.3rem 0.5rem;
    @include media-breakpoint-down(sm) {
      display: block;
    }
    
    a {
      display: inline-block;
      padding: 0.2rem 0.75rem;
      border-left: 1px solid rgba($color-text-alt, 0.5);
      font-weight: 500;
      
      &:first-child {
        border-left-width: 0;
      }

      &:active {
        color: $color-text-alt;
      }
    }
  }

  &__mobile {
    position: relative;
    display: flex;
    background: $color-white;

    @include media-breakpoint-up(md){
      display: none;
    }
  }

  &__logo {
    width: 150px;
    margin-left: 15px;
    margin-top: 5px;
    @include media-breakpoint-down(sm) {
      margin-top: unset;
    }
    
    &:active {
      opacity: 0.75;
    }

    img {
      width: 100%;
      display: block;
      @include media-breakpoint-down(sm) {
        height: 70px;
        object-fit: contain;
        object-position: left center;
      }
    }
  }

  &__search-box {
    position: absolute;
    left: 0;
    top: 50px;
    width: 100%;
    background-color: white;
    box-shadow: 0 2px 23px rgba(0,0,0,0.25);
    padding: 80px 0;
    -webkit-clip-path: inset(0% 0% 100% 0%);
    clip-path: inset(0% 0% 100% 0%);
    pointer-events: none;
    transition: all 500ms cubic-bezier(0.6, 0, 0.4, 1);
    @include media-breakpoint-down(sm) {
      top: 110px;
    }

    &--active {
      -webkit-clip-path: inset(0% 0% -25px 0%);
      clip-path: inset(0% 0% -25px 0%);
      pointer-events: auto;
    }

    &-content {
      text-align: center;

      h2 {
        color: $color-green;
        margin-bottom: 40px;
      }

      p {
        max-width: 400px;
        margin: 0 auto;
        font-size: 13px;
        line-height: 20px;
      }

      form {
        display: inline-flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: stretch;
        border: 2px solid $color-green-25;
        border-radius: 4px;
        max-width: 615px;
        width: 100%;
        overflow: hidden;
        margin-bottom: 40px;

        input {
          -webkit-appearance: none;
          appearance: none;
          color: $color-text;

          &[type="text"] {
            width: 80%;
            background-color: $color-gray-35;
            padding: 18px;
          }

          &[type="submit"] {
            flex-grow: 1;
            background-color: $color-green-25;
            color: $color-white;
            cursor: pointer;

            &:hover {
              background-color: $color-green;
            }

            &:active {
              color: rgba($color-white, 0.5);
            }
          }
        }
      }
    }
  }

  &__mobilesearch {
    display: none;
    position: absolute;
    top: calc(50% - 18px);
    right: 95px;

    @include media-breakpoint-down(sm) {
      display: block;
    }

    &:active {
      svg {
        path {
          fill: $color-primary;
        }
      }
    }

    svg {
      display: block;
      width: 36px;
      height: 36px;

      path {
        fill: $color-primary-alt;
      }
    }
  }
}
