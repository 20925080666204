.d-media {
  .d-media-wrapper {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: stretch;
    margin-top: $layout-grid-gutter * 2;
    padding-top: $layout-grid-gutter * 2;
    border-top: 1px solid $color-border;
    border-bottom: 1px solid $color-border;
    @include media-breakpoint-down(md) {
      margin-top: $layout-grid-gutter;
      padding-top: $layout-grid-gutter;
    }
  }

  .d-media-videos {
    width: calc(50% - #{$layout-grid-gutter});
    margin-right: $layout-grid-gutter * 2;
    @include media-breakpoint-down(sm) {
      width: 100%;
      margin: 0 0 $layout-grid-gutter * 2;
    }

    .d-media-video {
      position: relative;
      margin-bottom: $layout-grid-gutter;
      border-radius: 3px;
      overflow: hidden;

      &::before {
        content: "";
        display: block;
        padding-bottom: 56.25%;
      }

      iframe, video {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    h3 {
      color: $color-green;
      margin-bottom: $layout-grid-gutter / 2;
    }
  }

  .d-media-podcasts {
    width: calc(50% - #{$layout-grid-gutter});
    @include media-breakpoint-down(sm) {
      width: 100%;
      border-top: 1px solid $color-border;
      padding-top: $layout-grid-gutter * 2;
    }

    ul {
      list-style-type: none;
    }
  }

  .d-media-podcast {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: stretch;
    margin-bottom: $layout-grid-gutter;

    &:last-child {
      margin-bottom: 0;
    }

    h3 {
      color: $color-green;
      margin-bottom: $layout-grid-gutter / 2;
    }

    p {
      margin-bottom: 0;
    }

    div {
      flex-grow: 1;
    }

    a {
      flex-shrink: 0;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      border: 1px solid $color-green;
      width: 4.5rem;
      height: 4.5rem;
      border-radius: 6px;
      text-align: center;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 500;
      color: $color-green;
      margin-left: $layout-grid-gutter;

      &:hover {
        background-color: $color-green;
        color: $color-white;

        svg path {
          fill: $color-white;
        }
      }

      &:active {
        svg {
          opacity: 0.5;
        }
      }

      svg {
        display: block;
        width: 24px;
        height: 24px;

        path {
          fill: $color-green;
        }
      }
    }
  }

  .d-media-galleries {
    width: 100%;
    margin-top: 36px;
    padding-top: 36px;
    border-top: 1px solid $color-border;
  }

  .d-media-title {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $layout-grid-gutter;
    @include media-breakpoint-down(sm) {
      flex-flow: column nowrap;
      justify-content: flex-start;
      align-items: flex-start;
    }

    h2 {
      color: $color-green;
    }

    a {
      margin-left: $layout-grid-gutter;
      @include media-breakpoint-down(sm) {
        margin: $layout-grid-gutter 0 0;
      }
    }
  }
}
