.events-date {
    @include aspect-ratio(80px, 80px);
    width: 60px;
    max-width: 80px;
    background-color: $color-green-25;
    color: $color-white;
    font-family: $font-family-roboto;
    text-transform: uppercase;
    text-align: center;
    border-radius: 4px;

    @include media-breakpoint-up(sm){
        width: 100%;
        max-width: 80px;
    }

    &__cont, .inner {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }

    .day {
        font-size: 28px;
        font-weight: $font-weight-bold;
        line-height: 1;
        display: block;
        width: 100%;

        @include media-breakpoint-up(sm){
            font-size: 32px;
        }
            
    }

    .month {
        font-size: 16px;
    }
}

.events-list {
    border-left: 1px solid rgba($color-text, 0.45);
    padding-left: 15px;
    margin-left: 10px;

    @include media-breakpoint-up(sm){
        padding-left: 30px;
    }
}

.events-item {
    display: block;
    margin-bottom: $layout-spacing-section;
    
    &__title {
        color: $color-green;
        margin-bottom: 15px;

        @include hoverTitle;
    }

    &__eyebrow {
        text-transform: uppercase;
        font-size: 12px;
    }

    &__desc {
        font-size: 16px;
        line-height: 24px;
    }

    .category {
        @include media-breakpoint-down(sm){
            display: block;
            line-height: 1;
            margin-bottom: 15px;
        }
    }

    .button {
        margin-top: 15px;

        .ico-arrow-right {
            opacity: 0;
            transition: all 0.3s ease;
        }
    }

    &:hover {
        .ico-arrow-right {
            opacity: 1;
            transform: translateX(3px);
        }

        .events-item__title {
            span {
                border-bottom: 1px solid $color-green;
            }
        }
    }
}


.category {
    font-weight: $font-weight-bold;

    &--red {
        color: #D0021B;
    }
    &--orange {
        color: #F58723;
    }
    &--violet {
        color: #9013FE;
    }
    &--black {
        color: $color-black;
    }
    &--lightblue {
        color: #4A90E2;
    }
    &--bronze {
        color: #8B572A;
    }
}

.field-calendar {
    .icon-calendar  {
        background-image: url('../images/ico-calendar.svg');
        display: inline-block;
        width: 16px;
        height: 16px;

        right: 10px;
        top: 9px;
        position: absolute;
    }

    .field-input {
        color: #2C2C2C;
        width: 165px;
        height: 38px;
        border: 1px solid #ccc;
        font-size: 12px;
        position: relative;
        border-radius: 2px;
        font-family: $font-family-lato;

        &__input {
            width: 100%;
            padding-right: 35px;
            height: 100%;
            padding-left: 15px;
        };
    }
}

.field-filters {
    margin-bottom: $layout-spacing-section;
    @include media-breakpoint-up(md){
        margin-bottom: 0;
    }

}

.field-dropdown {
    margin: $layout-spacing-section-md 0;
    border: 1px solid #ccc;
    border-radius: 3px;
    max-width: 287px;

    @include media-breakpoint-up(sm){
        border: 1px solid #979797;
    }

    @include media-breakpoint-up(md){
        margin: $layout-spacing-section 0;
    }

    &__button {
        font-family: $font-family-lato;
        font-size: 12px;
        text-transform: uppercase;
        padding-left: 15px;
        position: relative;
        height: 42px;
        display: flex;
        align-items: center;

        .icon-dropdown {
            background-image: url('../images/arrow-down.svg');
            display: inline-block;
            width: 11px;
            height: 7px;

            right: 15px;
            top: 17px;
            position: absolute;
        }
    }

    &__options {
        display: none;
        font-size: 12px;
        text-transform: uppercase;
        border-top: 1px solid #979797;
        padding: 25px 15px;

        @include media-breakpoint-up(md){
            display: block;
        }

        a {
            display: block;
            padding: 9px 0;
        }
    }
}
