
// Colors
$color-white: #ffffff;
$color-black: #000000;
$color-gray-25: #f2f2f2;
$color-gray-30: #F3F3F3;
$color-gray-35: #F5F5F5;
$color-gray-40: #f9f9f9;
$color-gray-50: #e5e5e5;
$color-gray-100: #d9d9d9;
$color-gray-150: #dcdcdc;
$color-gray-200: #cccccc;
$color-gray-300: #b3b3b3;
$color-gray-400: #999999;
$color-gray-500: #808080;
$color-gray-600: #666666;
$color-gray-700: #4c4c4c;
$color-gray-800: #333333;
$color-gray-900: #1a1a1a;

$color-green: #31815F;
$color-green-25: #2AAD74;
$color-green-50: #B8E986;
$color-text-40: #4D4D4D;

$color-light: $color-white;
$color-dark: $color-black;
$color-text: #696969;
$color-text-alt: $color-white;
$color-border: $color-gray-200;
$color-primary: $color-green;
$color-primary-alt: $color-green-25;

