.news-panel {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;
  height: auto;
  @include media-breakpoint-up(md) {
    flex-flow: row nowrap;
  }

  &__heading {
    padding: 20px;
    @include media-breakpoint-up(md) {
      border-width: 0;
    }

    h2 {
      padding: 0;
    }
  }

  &__title {
    margin: 0;
    padding: 0 25px;
  }

  &__col {
    display: block;
    flex-shrink: 0;
    max-width: unset;

    &:nth-child(2) {
      @include media-breakpoint-up(md) {
        width: 80%;        
      }
    }

    &:nth-child(3) {
      flex-grow: 1;
      display: inline-flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
    }
  }

  &__items {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: stretch;
    @include media-breakpoint-up(md) {
      flex-flow: row nowrap;
    }
  }

  &__item {
    flex-basis: 0;
    flex-grow: 1;
    padding: 20px;
    height: auto;
    border: 1px solid $color-white;
    border-width: 1px 0 0 0;
    width: 100%;
    @include media-breakpoint-up(md) {
      border-width: 0 0 0 1px;
    }

    &:last-child {
      border-width: 1px 0 1px 0;
      @include media-breakpoint-up(md) {
        border-width: 0 1px 0 1px;
      }
    }

    &:active {
      background-color: rgba($color-green, 0.2);
    }
  }

  &__controls {
    flex-grow: 1;
    height: 100%;
    border-width: 0;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;

    &:active {
      background-color: rgba($color-green, 0.2);
    }

    .button--arrow {
      display: block;
      width: 24px;
      height: 24px;

      svg {
        display: block;
        pointer-events: none;
        width: 100%;
        height: 100%;
      }
    }
  }
}