h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: normal;
    margin: 0;
    padding: 0;
    font-family: $font-family-headings;
    line-height: 1.1em;
}

p {
    font-family: $font-family-roboto;
	margin-top: 0;
    margin-bottom: 30px;
    font-size: 15px;
	letter-spacing: 0.5px;
    line-height: 24px;
}

strong {
    font-weight: $font-weight-bold;
}

.priority-1 {
    font-family: $font-family-headings;
    font-size: 50px;
    letter-spacing: 0.5px;
    line-height: 1;
}

.priority-3 {
    font-family: $font-family-headings;
    font-size: 32px;
    letter-spacing: 0.06px;
    line-height: 1;
}

.priority-4 {
    font-family: $font-family-headings;
    font-size: 22px;
    line-height: 29px;
}

.priority-5 {
    font-family: $font-family-headings;
    font-size: 26px;
    line-height: 29px;
}