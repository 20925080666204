.slider-top {
  height: 100%;
  border-radius: 4px;

  .swiper-container {
    height: 100%;
    border-radius: 4px;
  }
  
  &__indexes {
    position: absolute;
    list-style-type: none;
    left: 50%;
    bottom: 20px;
    transform: translateX(-50%);
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }

  &__index {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: $color-white;
    margin: 0 3.5px;
    opacity: 0.35;
    transition: opacity 500ms ease-in-out;
    cursor: pointer;

    &:hover {
      opacity: 0.75;
    }

    &:active {
      transition: unset;
      opacity: 0.5;
    }

    &--active {
      opacity: 1;
    }
  }
}