.logo-box {
  margin-bottom: 30px;
  @include media-breakpoint-up(md){
    margin-bottom: 0;
  }

  &__top {
    display: block;
    background-color: $color-gray-35;
    margin-bottom: 13px;
    border-radius: 4px;
    overflow: hidden;
    padding: 1.5rem;
    height: 160px;
    @include media-breakpoint-up(lg){
      margin-bottom: 18px;
    }

    &:active {
      opacity: 0.75;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-position: center;
      object-fit: contain;
    }
  }

  &__bottom {
    position: relative;
    display: block;
    height: 160px;
    border-radius: 4px;
    overflow: hidden;
    background-color: $color-gray-800;

    &:hover {
      img {
        opacity: 0.85;
      }
    }

    &:active {
      img {
        opacity: 1;
        transition: unset;
      }
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      opacity: 0.7;
      transition: opacity 500ms ease-out;
    }

    > div {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      text-align: center;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      color: white;
      padding: 0.5rem;
    }

    .subtitle {
      border-top: 1px solid rgba($color-white, 0.4);
      padding-top: 1rem;
      margin-top: 1rem;
    }
  }
}
