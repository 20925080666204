.section {
  margin-top: $layout-spacing-section;

  &--intro {
    margin-top: 0;

    @include media-breakpoint-up(sm){
      margin-top: $layout-spacing-section-md;
    }
    @include media-breakpoint-up(lg){
      margin-top: $layout-spacing-section;
    }
  }

  &--cards {
    .row:nth-child(2) {
      margin-bottom: $layout-grid-gutter * -1;
    }
  }

  &--panel {
    .button-list {
      display: block;
      margin-bottom: $layout-spacing-section-md;
      height: auto;

      @include media-breakpoint-up(sm){
        margin-bottom: 0;
        display: flex;
        flex-direction: column;
        height: 100%;
        flex: 1;
      }
    }

    .button--content {
      margin-bottom: 9px;
      @include media-breakpoint-up(sm){
        margin-bottom: 0;
      }
    }
  }

  &--footerbuttons {
    background-color: rgba($color-green, 0.14);
    padding: 2rem 0;

    .footer__title {
      color: $color-green;
    }

    .button {
      @include media-breakpoint-down(sm) {
        margin-bottom: 1rem;
      }
    }
  }

}

.section--green {
  @include media-breakpoint-up(sm){
    background-color: rgba($color-green, 0.13);
  }
}


.section-text {
  font-family: $font-family-roboto;
  margin-top: $layout-spacing-section;
}

.section--cards-text {
  margin-bottom: -$layout-spacing-section;

  .button--back {
    margin-top: 30px;
    margin-bottom: 90px;
  }
 
  .separator { 
    margin-top: 0;
    margin-bottom: $layout-spacing-section;
  }
}

.section-rte-page {
  color: $color-text-40;

  .separator {
    margin-bottom: 40px;
    display: none;

    @include media-breakpoint-up(md){
      display: block;
    }
  }

  .section__footer {
    margin-top: 30px;

    @include media-breakpoint-up(md){
      margin-top: 40px;
    }
  }

  .section__header {
    display: flex;
    font-size: 12px;
    margin-bottom: 20px;

    .date {
      padding-right: 5px;
    }
  
    .tags {
      padding-left: 4px;
      a {
        color: $color-green-25;
      }
    }
  }
}


.section-contacts {
  background-color: rgba($color-green, 0.14);
  line-height: 24px;
  color: $color-text-40;
  padding: 35px;
  margin-top: $layout-spacing-section;
  margin-bottom: 0;
  text-align: center;

  &__title {
    font-size: 16px;
    font-family: $font-family-headings;
    margin-bottom: 10px;

    @include media-breakpoint-up(md){
      font-size: 18px;
      margin-bottom: 30px;
    }
  }

  &__item {
    margin: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;

    @include media-breakpoint-down(md){
      border-bottom: 1px solid $color-white;
      padding: 20px 0;
      margin: 0;
    }

    &:last-child {
      @include media-breakpoint-down(md){
        border: none;
      }
    }
  }

  .contact-media {
    width: 70px;
    height: 70px;
    margin-right: 20px;

    @include media-breakpoint-down(md){
      display: none;
    }

    img {
      width: 100%;
      object-fit: cover;
    }
  }

  .contact-detail {
    &__name {
      color: $color-green;
      font-weight: $font-weight-bold;
    }

    span {

      &::after {
        content: '|';
        margin-right: 5px;
        padding-left: 5px;
        color: $color-text-40;
      }

      @include media-breakpoint-down(md){
        display: block;

        &::after {
          display: none;
        }
      }
    }
  }
}

.section__load-more {
  text-align: center;
  padding-top: 55px;
  padding-bottom: 120px;
}
