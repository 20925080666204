.d-gallery {
  .d-gallery-wrapper {
    margin-top: $layout-spacing-section;
    padding-top: $layout-spacing-section;
    padding-bottom: $layout-spacing-section;
    border-top: 1px solid $color-border;
    border-bottom: 1px solid $color-border;
    @include media-breakpoint-down(md) {
      margin-top: $layout-spacing-section-md;
      padding-top: $layout-spacing-section-md;
      padding-bottom: $layout-spacing-section-md;
    }

    ul {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-items: stretch;

      li {
        position: relative;
        width: 25%;
        cursor: pointer;
        user-select: none;
        @include media-breakpoint-down(md) {
          width: 33.3333333%;
        }
        @include media-breakpoint-down(sm) {
          width: 100%;
        }

        &:hover {
          &::after,
          svg {
            opacity: 1;
          }
        }

        &:active {
          &::after {
            background-color: rgba($color-green, 0.85);
          }
        }

        &::before {
          content: "";
          display: block;
          padding-bottom: 100%;
        }

        &::after {
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background-color: rgba($color-green, 0.75);
          mix-blend-mode: multiply;
          opacity: 0;
          transition: opacity 300ms ease-out;
        }

        img {
          display: block;
          width: 100%;
          height: 100%;
          object-position: center;
          object-fit: cover;
          position: absolute;
          top: 0;
          left: 0;
        }

        svg {
          display: block;
          position: absolute;
          top: calc(50% - 12px);
          left: calc(50% - 12px);
          transition: opacity 300ms ease-out;
          z-index: 2;
          opacity: 0;

          polygon {
            fill: $color-white;
          }
        }
      }
    }
  }
}
