.banner {
  height: 78px;
  background: $color-gray-300;
  position: relative;
  margin-bottom: 11px;

  @include media-breakpoint-up(md){
    margin-bottom: 0;
  }

  @include media-breakpoint-up(lg){
    height: 106px;
  }

  a {
    display: block;
  }

  &__media {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    object-fit: cover;
  }

  &__inner {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    background: rgba($color-gray-300,0.2);
  }

  span {
    font-family: $font-family-roboto;
    line-height: 1;
    font-size: 12px;
    display: block;
    text-transform: uppercase;
    color: $color-white;
  }
}
