/*----- PX to EM  -----*/
@function em($pixels, $context: 16) {
    @return $pixels/$context;
}

/* ----- PX TO PERC calc -----*/
@function perc($width, $container-width: 1920px) {
	@return percentage($width / $container-width);
}

/* Used inside vw mixin */
@function get-vwContext($fontbase, $base) {
	$decimal: ($fontbase / $base) * 100;
	@return $decimal;
}

/* VW to Pixel: */
@function vw($pixels, $base: 1680px, $fontbase: 16px) {
	$vw-context: get-vwContext($fontbase, $base);
	@return #{($pixels / $fontbase) * $vw-context}vw;
}

/*----- Touch  -----*/
@mixin is-touch {
    .touch & {
        @content;
    }
}

/*----- Touch  -----*/
@mixin is-no-touch {
    .no-touch & {
        @content;
    }
}

/*----- Non touch hover -----*/
@mixin hover {
    .no-touch & {
        &:hover {
            @content;
        }
    }
}

/*----- Has HTML class  -----*/
@mixin isIE11 {
    .IE-11 & {
    @content;
    }
}

/*---- Placeholder Color ----*/
@mixin placeholder {
    &::-webkit-input-placeholder {
        @content;
    }

    &::-moz-placeholder {
        @content;
    }

    &:-moz-placeholder {
        @content;
    }

    &:-ms-input-placeholder {
        @content;
    }
}

/*---- Inset Overlay ----*/
@mixin insetOverlay($opacity, $color: black) {
    box-shadow: inset 0 0 0 999em rgba($color, $opacity); // overlay
}

@mixin aspect-ratio($width, $height) {
    position: relative;
    &:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: ($height / $width) * 100%;
    }
    > .inner {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

@mixin hoverTitle {
    span {
        transition: border 0.3s ease;
        border-bottom: 1px solid transparent;
        margin-top: 20px;
    }
}