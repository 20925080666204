.hamburger {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  z-index: 1;
  pointer-events: auto;
  background: $color-green-25;
  width: 70px;
  height: 100%;
  border-right: 1px solid $color-white;
  cursor: pointer;
  transition: background-color 300ms ease-out;
  flex-shrink: 0;
  @include media-breakpoint-down(sm) {
    height: 70px;
    border-right-width: 0;
  }

  .header__mobile & {
    position: absolute;
    right: 0;
  }

  &__inner {
    position: relative;
    width: 30px;
    height: 20px;  
  }
   
  span {
    position: absolute;
    display: block;
    width: 100%;
    left: 0;
    height: 4px;
    background: $color-white;
    z-index: 1;
    transform-origin: 50% 50%;
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0), background-color 0.3s cubic-bezier(0.77,0.2,0.05,1.0), opacity 0.55s ease;
    pointer-events: none;

    &:nth-child(1) {
      top: 0;
    }

    &:nth-child(2) {
      top: calc(50% - 2px);
    }

    &:nth-child(3) {
      bottom: 0;
    }
  }


  &.is-open {
    background: $color-white;

    span {
      background: $color-green-25;
      opacity: 1;
    }

    span:nth-child(1) {
      transform: translateY(8px) rotate(45deg);
    }

    span:nth-child(2) {
      opacity: 0;
    }

    span:nth-child(3) {
      transform: translateY(-8px) rotate(-45deg);
    }    
  }

  // not for touch

  &:hover {
    @include media-breakpoint-up(md){
      background: $color-white;

      span {
        background: $color-green-25;
      }
    }
  }

}
