// by luca
.meetings-list {
  margin-top: 50px;
  padding-top: 50px;
  border-top: 1px solid $color-text;
  @include media-breakpoint-down(sm) {
    margin-top: 0;
    padding-top: 0;
    border-top-width: 0;
  }

  &__sidebar {
    @include media-breakpoint-down(md) {
      order: 1;
    }
  }

  &__content {
    @include media-breakpoint-down(md) {
      order: 0;
      margin-bottom: 4rem;
    }
  }

  &__box {
    margin-bottom: 50px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    @include media-breakpoint-down(md) {
      margin-bottom: 1rem;
      flex-flow: column nowrap;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__date {
    flex-shrink: 0;
    width: 80px;
    height: 80px;
    margin-right: 30px;
    background-color: $color-green-25;
    border-radius: 4px;
    display: inline-flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    color: $color-white;
    @include media-breakpoint-down(md) {
      margin: 0 0 1rem;
      width: 50px;
      height: 50px;
    }

    span:first-child {
      font-size: 2rem;
      font-weight: 700;
      line-height: 1em;
      margin-bottom: 0.1em;
      @include media-breakpoint-down(md) {
        font-size: 1.3rem;
      }
    }

    span:last-child {
      font-size: 1rem;
      font-weight: 700;
      line-height: 1em;
      text-transform: uppercase;
      opacity: 0.65;
      @include media-breakpoint-down(md) {
        font-size: 0.75rem;
      }
    }
  }

  &__events {
    flex-grow: 1;
    padding-left: 30px;
    border-left: 1px solid rgba($color-text, 0.5);
    align-self: stretch;
    @include media-breakpoint-down(md) {
      padding-left: 0;
      border-left-width: 0;
    }
  }

  &__event {
    margin-bottom: 50px;
    @include media-breakpoint-down(md) {
      margin-bottom: 2rem;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &-top {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
      font-size: 0.75rem;
      font-weight: 700;
      text-transform: uppercase;
      @include media-breakpoint-down(md) {
        flex-flow: column nowrap;
        align-items: flex-start;
      }
    }

    &-filters {
      display: inline-flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;

      span {
        display: inline-block;
        cursor: pointer;
        color: $color-green;
        
        &:hover {
          color: $color-green-25;
        }
        
        &:active {
          opacity: 0.75;
        }
        
        &::before {
          display: inline-block;
          content: "-";
          text-align: center;
          width: 1.5rem;
          color: $color-text;
        }
        
        &:first-child::before {
          @include media-breakpoint-down(md) {
            display: none;
          }
        }
      }
    }

    &-links {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;

      a {
        font-size: 0.75rem;
        font-weight: 700;
        color: $color-green-25;
        margin-left: 1rem;
  
        &:hover {
          color: $color-green;
        }
  
        &:active {
          opacity: 0.5;
        }
      }
    }

    &-location {
      font-weight: 400;
      color: $color-gray-500;

      &::before {
        display: inline-block;
        content: "-";
        text-align: center;
        width: 1.5rem;
        color: $color-text;
      }
    }

    h3 {
      font-size: 1.375rem;
      font-weight: 400;
      line-height: 2rem;
      color: $color-green;
      padding: 0.5rem 0;
    }

    p {
      font-size: 1rem;
      color: $color-text-40;
      letter-spacing: 0;
      margin-bottom: 1rem;
    }
  }

  &__button {
    display: inline-flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 2em;
    cursor: pointer;
    background-color: $color-gray-100;
    border-radius: 3px;
    padding: 0 0.75rem;
    color: $color-light;
    user-select: none;

    &:hover {
      background-color: $color-primary;
    }

    &:active {
      background-color: $color-primary-alt;
    }

    svg {
      width: 12px;
      height: 12px;
      margin-left: 0.75rem;

      path {
        fill: $color-light;
      }
    }
  }

  &__load-more {
    margin: 50px auto;
    text-align: center;
    cursor: pointer;

    > span {
      display: inline-flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      font-size: 0.75rem;
      font-weight: 700;
      color: $color-green-25;
      margin: 0 auto;
      user-select: none;

      &:hover {
        color: $color-green;

        svg path {
          fill: $color-green;
        }
      }

      &:active {
        opacity: 0.75;
      }

      svg {
        display: block;
        margin-right: 0.5rem;
  
        path {
          fill: $color-green-25;
        }
      }
    }
  }

  &__calendar,
  &__filters {
    border: 1px solid $color-border;
    border-radius: 3px;
    padding: 1rem;
  }

  &__calendar {
    margin-bottom: 50px;
    font-size: 0.75rem;
    background-color: $color-white;
    @include media-breakpoint-down(md) {
      margin-bottom: 1rem;
    }

    .el-calendar__title {
      padding: 0 0 0.5rem;
      font-weight: 700;
      color: $color-green-25;
    }

    .el-calendar__header {
      flex-flow: column nowrap;
      justify-content: flex-start;
      align-items: center;
      padding: 0 0 1rem;
    }

    .el-calendar__body {
      padding: 0;

      .el-calendar-table {
        td.is-today {
          color: $color-green-25;
        }

        td.is-selected {
          background-color: rgba($color-green-25, 0.1);
          color: $color-green-25;
        }

        .el-calendar-day {
          height: auto;
          text-align: center;

          &:hover {
            background-color: rgba($color-green-25, 0.05);
          }
        }
      }
    }
  }

  &__filters {
    h6 {
      text-align: center;
      font-family: $font-family-roboto;
      font-size: 0.75rem;
      font-weight: bold;
      line-height: 1.5em;
      margin-bottom: 1rem;
    }

    ul {
      list-style-type: none;

      li {
        &.is-active a {
          color: $color-green-25;
        }

        a {
          display: block;
          font-weight: 700;
          font-size: 0.75rem;
          text-transform: uppercase;

          &:hover {
            color: $color-green-25;
          }

          &:active {
            opacity: 0.75;
          }
        }
      }
    }
  }
}
