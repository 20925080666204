.hero {
  position: relative;
  height: 292px;
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: 30px;
  background-color: $color-gray-800;
  border-radius: 4px;
  overflow: hidden;
  @include media-breakpoint-up(md){
    height: 100%;
    width: 100%;
    margin: 0;
  }

  img {
    // filter: grayscale(100%);
    opacity: 0.5;
  }
  
  &__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: $color-white;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__heading {
    width: 100%;
    margin: 0 auto 30px;
    text-align: center;

    @include media-breakpoint-up(sm){
      width: 80%;
      margin: 0 auto;
    }
    @include media-breakpoint-up(md){
      width: 55%;
    }
  }

  &__title {
    font-size: 22px;
    @include media-breakpoint-up(sm){
      font-size: 28px;
    }
    @include media-breakpoint-up(lg){
      font-size: 42px;
    }
  }

  &__abstract {
    line-height: 22px;
    margin-top: 30px;

    @include media-breakpoint-down(sm){
      font-size: 14px;
      line-height: 18px;
    }
  }

  &__media {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__back {
    position: absolute;
    bottom: 35px;
    opacity: 0.6;
    width: auto;
    height: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 0;
    display: flex;
    align-items: center;
    margin-left: 0;
    flex-direction: row-reverse;

    @include media-breakpoint-up(md){
      left: 0;
      bottom: auto;
      border-right: 1px solid rgba($color-white, 0.45);
      flex-direction: row;
      margin-left: 12px;
      opacity: 1;
    }

    .label {
      font-family: $font-family-main;
      font-weight: $font-weight-bold;
      font-size: 11px;
      text-transform: uppercase;
      @include media-breakpoint-down(md){
        // font-size: 12px;
        display: none;
      }
    }
    
    .ico-arrow-left {
      display: block;
      height: 26px;
      width: 23px;

      @include media-breakpoint-up(md){
        height: 48px;
        width: 43px;
      }

      svg {
        width: 100%;
        height: 100%;
      }

      svg path:last-child {
        fill: $color-white;
      }
    }
  }
}
