.button {
  font-family: $font-family-main;
  
  &--text {
    position: relative;
    font-weight: $font-weight-medium;
    color: $color-green-50;
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    line-height: 1;

    &:hover {
      color: $color-green-25;
    }

    &:active {
      opacity: 0.8;
    }

    .ico-arrow-right {
      width: 18px;
      height: 18px;
      margin-left: 0.5em;

      svg path:last-child {
        fill: $color-green-25;
      }
    }
  }

  &--block {
    display: inline-block;
    cursor: pointer;

    .icon {
      svg {
        width: 100%;
        height: 100%;
      }

      svg path {
        fill: $color-green-25;
      }
    }

    .icon--reload  {
      width: 50px;
      height: 50px;
      margin: 0 auto 11px;
      transform: rotate(0);
      transition: transform 0.4s ease;
      
      svg {
        width: 100%;
        height: 100%;
      }
    }

    &:hover {
      .icon--reload {
        transform: rotate(45deg);
      }
    }
  }

  &--center {
    text-align: center;
    margin: 0 auto;
  }

  &--green-50 {
    color: $color-green-50;
  }

  &--green-25 {
    color: $color-green-25;
  }
  
  &--stroke {
    border: 1px solid $color-white;
    color: $color-white;
    width: 100%;
    border-radius: 4px;
    display: block;
    text-align: center;
    height: 54px;
    line-height: 54px;
    transition: all 0.3s ease;

    &:hover {
      background: $color-white;
      color: $color-green;
    }
  }
  
  &--stroke-alt {
    border: 1px solid $color-green;
    color: $color-green;
    width: 100%;
    border-radius: 4px;
    display: block;
    text-align: center;
    height: 54px;
    line-height: 54px;
    transition: all 0.3s ease;

    &:hover {
      background-color: $color-green;
      color: $color-white;
    }
    
    &:active {
      transition: unset;
      background-color: $color-green-25;
      border-color: $color-green-25;
    }
  }

  &--rte {
    background-color: $color-green;
    color: $color-white;
    display: inline-block;
    line-height: 36px;
    white-space: nowrap;
    padding: 0 1rem;
    border-radius: 3px;
    text-decoration: none;

    &:hover {
      background-color: $color-green-25;
    }

    &:active {
      color: rgba($color-white, 0.5);
    }
  }

  &--content {
    display: flex;
    align-items: center;
    background: $color-green;
    border-radius: 4px;
    padding: 14px 18px;
    @include media-breakpoint-down(xl){
      justify-content: space-between;
    }

    &:hover {
      background: darken($color-green, 5%);
    }

    &:active {
      .title {
        opacity: 0.5;
      }
    }

    span {
      display: block;
    }

    .title {
      font-size: 18px;
      line-height: 29px;
      color: $color-green-50;

      @include media-breakpoint-up(xl){
        font-size: 24px;
      }
    }

    .info {
      display: none;
      font-size: 14px;
      line-height: 20px;
      color: $color-white;

      @include media-breakpoint-up(xl){
        display: block;
      }
    }

    .ico-arrow-right  {
      width: 30px;
      height: 30px;
      svg path:last-child {
        fill: $color-green-50;
      }
    }

  }

  &--next,
  &--back {
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    .label {
      font-family: $font-family-main;
      font-weight: $font-weight-bold;
      font-size: 12px;
      text-transform: uppercase;
      color: $color-green-25;
    }
    
    .ico-arrow-left {
      display: block;
      height: 23px;
      width: 23px;
      transition: transform 0.2s ease;

      svg {
        width: 100%;
        height: 100%;
      }

      svg path:last-child {
        fill: $color-green-25;
      }
    }

    &:hover {
      .ico-arrow-left {
        transform: translateX(-5px);
      }
    }
  }

  &--next {
    margin-bottom: 40px;

    svg {
      transform: rotate(180deg);
    }

    &:hover {
      .ico-arrow-left {
        transform: translateX(5px);
      }
    }
  }
}

.button-list {
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  height: 100%;
}
