
.container,
.container-fluid {
	width: 100%;
	max-width: $layout-container-maxwidth;
	padding-left: $layout-page-padding;
	padding-right: $layout-page-padding;
}


.row {
	margin-left: -$layout-page-padding/2;
	margin-right: -$layout-page-padding/2;

	@include media-breakpoint-up(md){
		margin-left: -$layout-page-padding/2;
		margin-right: -$layout-page-padding/2;
	}
}

[class*=" col-"],
[class^="col-"] {
	padding-right: $layout-page-padding/2;
	padding-left: $layout-page-padding/2;

	@include media-breakpoint-up(md){
		padding-right: $layout-page-padding/2;
		padding-left: $layout-page-padding/2;
	}
 }

.row-large-gutter {
	$gutter-size: 60px;

	margin-left: -$layout-page-padding/2;
	margin-right: -$layout-page-padding/2;

	@include media-breakpoint-up(md){
		margin-left: -$gutter-size/2;
		margin-right: -$gutter-size/2;
	}
	
	[class*=" col-"],
	[class^="col-"] {
		padding-right: $layout-page-padding/2;
		padding-left: $layout-page-padding/2;

		@include media-breakpoint-up(md){
			padding-right: $gutter-size/2;
			padding-left: $gutter-size/2;
		}
	}
} 