.section-contacts {
  text-align: left;

  &__item {
    justify-content: flex-start;
  }

  .contact-media {
    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}