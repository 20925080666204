.home-news {
  h2 {
    color: $color-primary;
  }

  .home-news__top {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    @include media-breakpoint-down(sm) {
      flex-flow: column nowrap;
      justify-content: flex-start;
      align-items: flex-start;
    }

    h2 {
      @include media-breakpoint-down(sm) {
        margin-bottom: 1rem;
      }
    }

    .button {
      color: $color-green;

      &:hover {
        text-decoration: underline;
      }

      &:active {
        color: rgba($color-green, 0.5);
      }

      & svg path {
        fill: $color-green;
      }
    }
  }

  .home-news__boxes {
    margin-top: 1rem;
    border-radius: 3px;
    overflow: hidden;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: stretch;

    a {
      width: calc(33.333333% - 1px);
      flex-shrink: 0;
      padding: 1.5rem;
      margin: 0 1px 1px 0;
      background-color: rgba($color-green, 0.14);
      transition: background-color 300ms ease-in-out;
      @include media-breakpoint-down(md) {
        width: calc(50% - 1px);
      }
      @include media-breakpoint-down(sm) {
        width: 100%;
        margin-right: 0;
      }

      &:hover {
        background-color: rgba($color-green, 0.21);

        h3 {
          text-decoration: underline;
        }
      }

      &:active {
        transition: unset;
        background-color: rgba($color-green, 0.28);
      }

      h3 {
        font-family: $font-family-roboto;
        font-weight: $font-weight-medium;
        font-size: 17px;
        line-height: 20px;
        color: $color-green;
        margin-bottom: 10px;
      }

      p {
        font-size: 12px;
        line-height: 16px;
        font-family: $font-family-roboto;
        margin-bottom: 13px;
      }

      .button {
        color: $color-green;
      }
    }
  }
}
